<template>
	<div>
		<v-row v-if="passwordChanged" :dense="compactVisualization">
			<v-col cols="12">
				<v-alert prominent text type="success">
					Password cambiata!
				</v-alert>
				<div class="d-flex justify-center mt-10">
					<v-btn small to="/">
						Torna alla home
					</v-btn>
				</div>
			</v-col>
		</v-row>
		<v-form v-else ref="form" @submit.prevent="submit">
			<v-row :dense="compactVisualization">
				<v-col cols="12">
					<div class="d-flex flex-column mt-5">
						<v-text-field v-model="oldPassword" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Vecchia password" :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'" :type="passwordVisible ? 'text' : 'password'" :rules="[rules.required]" @click:append="passwordVisible = !passwordVisible" />
						<v-alert v-if="wrongOldPassword" dense type="error">
							la vecchia password non corrisponde
						</v-alert>
					</div>
					
					<div class="d-flex flex-column mt-10">
						<v-text-field v-model="newPassword1" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Nuova password" :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'" :type="passwordVisible ? 'text' : 'password'" :rules="[rules.required, rules.min]" loading @click:append="passwordVisible = !passwordVisible">
							<template v-slot:progress>
								<v-progress-linear absolute :value="passwordStrengthPercent" :color="passwordStrengthColor" />
							</template>
						</v-text-field>
						
						<v-alert v-if="newPassword1 && (passwordStrength === 0 || passwordStrength === 1)" dense text type="error">
							la nuova password non è abbastanza complessa
						</v-alert>
						<v-alert v-if="newPassword1 && passwordStrength === 2" dense text type="warning">
							la nuova password non è molto complessa
						</v-alert>
					</div>
					
					<div class="d-flex flex-column mt-5">
						<v-text-field v-model="newPassword2" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Ripeti nuova password" :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'" :type="passwordVisible ? 'text' : 'password'" :rules="[rules.required, rules.min]" @click:append="passwordVisible = !passwordVisible" />
						<v-alert v-if="isNewPasswordDifferent" dense text type="error">
							la password ripetuta non coincide
						</v-alert>
					</div>

					<v-alert v-if="avvisoPasswordSuggerita" type="info">
						Prendi nota della password suggerita prima di procedere al cambio
					</v-alert>

					<div class="d-flex mt-10 justify-space-between align-baseline">
						<v-btn color="success" :disabled="!oldPassword" x-small @click="suggerisciPassword">
							Suggeriscimi una password sicura
						</v-btn>
						<v-btn color="warning" :disabled="!formIsValid" type="submit">
							Cambia
						</v-btn>
					</div>

					<div v-if="genericError" class="mt-10">
						<v-alert type="error">
							{{ genericError }}
						</v-alert>
					</div>
				</v-col>
			</v-row>
		</v-form>

		<loading :loading="loading" />
	</div>
</template>

<script>
/**********************************************************************************************
**** COMPONENTE per CAMBIO PASSWORD ***********************************************************
***********************************************************************************************
*/
import Loading from '@/components/loading'
import {Entropy} from 'entropy-string'
import zxcvbn from 'zxcvbn'
export default {
	name: 'ChangePassword',
	components: { Loading },
	data: () => {
		return {
			loading: false,
			oldPassword: '',
			wrongOldPassword: false,
			passwordChanged: false,
			newPassword1: '',
			newPassword2: '',
			passwordVisible: false,
			avvisoPasswordSuggerita: false,
			genericError: '',
			rules: {
				required: value => !!value || 'Campo obbligatorio',
				min: v => v.length >= 8 || 'Almeno 8 caratteri',
			},
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		/**
     * passwordStrength is the score calculated by zxcvbn
     * @return {Number} Password Strength Score
     */
		passwordStrength () {
			return this.newPassword1 ? zxcvbn(this.newPassword1).score : 0
		},
		passwordStrengthPercent () {
			return ((this.passwordStrength ) / 4) * 100
		},
		passwordStrengthColor () {
			if (this.passwordStrength === 1)
				return 'error' 
			else if (this.passwordStrength === 2)
				return 'warning' 
			else if (this.passwordStrength === 3)
				return 'info' 
			else if (this.passwordStrength === 4)
				return 'success' 
			else
				return 'error' 
		},
		isNewPasswordDifferent() {
			if(this.newPassword1 && this.newPassword2) {
				return this.newPassword1!==this.newPassword2
			} 
			else return false
		},
		formIsValid() {
			return this.newPassword1 && this.newPassword2 && this.oldPassword && !this.isNewPasswordDifferent && this.passwordStrength >= 2
		}
	},
	mounted() {
	},
	methods: {
		suggerisciPassword() {
			this.passwordVisible = true
			const entropy = new Entropy()
			this.newPassword1 = entropy.string() 
			this.newPassword2 = this.newPassword1
			this.avvisoPasswordSuggerita = true
		},
		submit() {
			this.loading = true
			this.genericError = ''
			this.wrongOldPassword = false
			this.passwordChanged = false
			this.$store.dispatch('genericApiPost', {apiUrl: '/Users/ChangePassword', value: {OldPassword: this.oldPassword, NewPassword: this.newPassword1}}).then((data) => {
				// console.warn('submit', data)
				if(data.ChangePasswordResult === -99){
					this.wrongOldPassword = true
				} else if(data.ChangePasswordResult === 0){
					this.passwordChanged = true	
				} else {
					this.genericError = 'Errore ' + data.ChangePasswordResult
				}
				this.loading = false
			}).catch(error => {
				console.error('submit', error)
				this.genericError = 'Errore ' + error
				this.loading = false
			})
		},
	},
}
</script>

<style scoped lang="less">

</style>
<template>
	<div>
		<guide-panel id="impostazioni.profilo.profilo" title="DATI PERSONALI">
			In questa sezione è possibile modificare/aggiornare i propri dati.<br>
			I dati relativi a "Codice organizzato" e "Ragione Sociale" non possono essere modificati.
			I restanti campi <strong>non</strong> sono obbligatori.
		</guide-panel>
		<v-row class="mt-4">
			<v-col md="6"><v-text-field v-model="userData.UserName" disabled label="Codice organizzato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
			<v-col md="6"><v-text-field v-model="userData.RagSociale" disabled label="Ragione sociale" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
			<v-col md="12"></v-col>
			<v-col md="6"><v-text-field v-model="userData.Indirizzo" label="Indirizzo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
			<v-col md="6"><v-text-field v-model="userData.Cap" label="Cap" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
			<v-col md="6"><v-text-field v-model="userData.Localita" label="Località" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
			<v-col md="6"><v-autocomplete v-model="userData.Provincia" label="Provincia" :items="listaProvincie" item-text="NomeProv" item-value="Sigla" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" dense></v-autocomplete> </v-col>
			<v-col md="12"></v-col>
			<v-col md="6"><v-text-field v-model="userData.Responsabile" label="Responsabile" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
			<v-col md="6"><v-text-field v-model="userData.ResponsabileClienti" label="ResponsabileClienti" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
			<v-col md="12"></v-col>
			<v-col md="6"><v-text-field v-model="userData.Telefono" label="Telefono" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
			<v-col md="6"><v-text-field v-model="userData.Email" label="Email" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" /></v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="text-right">
				<v-btn color="primary" :loading="loading" @click="saveData">Aggiorna</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="12">
				<errors-container :errors="errors"></errors-container>
				<debug-panel label="userData">
					{{ userData }}
				</debug-panel>
			</v-col>
		</v-row>
		<loading :loading="loading" />
	</div>
</template>

<script>
/**********************************************************************************************
**** COMPONENTE per MODIFICA PROFILO UTENTE ***********************************************************
***********************************************************************************************
*/
import Loading from '@/components/loading'
import DebugPanel from '@/components/debug-panel'
import GuidePanel from '@/components/guide-panel'
import ErrorsContainer from '@/components/errors-container'
export default {
	name: 'ProfiloUtente',
	components: { Loading,DebugPanel,GuidePanel,ErrorsContainer },
	data: () => {
		return {
			loading: false,
			listaProvincie: [],
			listaComuni: [],
			listaStati: [],
			errors: [],
			userData: {},
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		darkTheme() { return this.$vuetify.theme.dark },
		currentUser() { return this.$store.getters['currentUser'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
	},
	mounted() {
		this.caricaProvince()
		this.loadData()
	},
	methods: {
		caricaProvince() {
			this.$store.dispatch('genericApiPost', {apiUrl: '/card/listaProvinceAttuali'}).then(result => {
				this.listaProvincie = result
				if(!result)
					this.errors.push({text: 'Errore: non è stato possibile scaricare l\'elenco delle provincie (attuali)'})
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				
			})
		},
		loadData() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/users/getuser', value: {UserName: this.currentUser.UserName}}).then(result => {
				this.userData = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		saveData() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/users/setuser', value: this.userData}).then(result => {
				this.userData = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		}
	},
}
</script>

<style scoped lang="less">

</style>
<template>
	<standard-container title="Impostazioni" description="" icon="mdi-format-list-checks">
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab key="tabImpostazioni">
					Impostazioni
				</v-tab>
				<v-tab key="tabChangePassword">
					Cambio password
				</v-tab>
				<v-tab key="tabProfiloUtente">
					Dati personali
				</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-tabs-items v-model="currentTab">
					<!-- tabImpostazioni -->
					<v-tab-item key="tabImpostazioni">
						<v-list>
							<v-list-item>
								<v-list-item-icon>
									<v-icon v-if="darkTheme">
										mdi-lightbulb-on-outline
									</v-icon>
									<v-icon v-else>
										mdi-lightbulb-on
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>Tema</v-list-item-title>
									<v-list-item-subtitle>Cambia il colore dell'interfaccia</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn v-if="darkTheme" small light @click="toggleTheme">
										chiaro
									</v-btn>
									<v-btn v-else small dark @click="toggleTheme">
										scuro
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider />
							
							<v-list-item>
								<v-list-item-icon>
									<v-icon>mdi-bug</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>Debug mode</v-list-item-title>
									<v-list-item-subtitle>Attiva la visualizzazione degli elementi di debug</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn v-if="viewDebugElements" small color="error" @click="toggleDebugElements">
										disattiva
									</v-btn>
									<v-btn v-else small color="success" @click="toggleDebugElements">
										attiva
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider />
							
							<v-list-item>
								<v-list-item-icon>
									<v-icon>mdi-border-bottom-variant</v-icon>
								</v-list-item-icon>
								<v-list-item-content style="align-self:start">
									<v-list-item-title>
										Tipologia campi edit
									</v-list-item-title>
									<v-list-item-subtitle>definisce l'aspetto grafici dei campi di edit</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-list-item-action-text>
										<div class="d-flex flex-column justify-space-between" style="min-height:180px; min-width:250px;margin-top:10px">
											<div class="d-flex">
												<v-text-field
													:filled="tceFilled" 
													:solo="tceSolo" 
													:outlined="tceOutlined" 
													:dense="tceDense" 
													label="Esempio" 
													value="testo esempio"
												/>
											</div>
											<div class="">
												<v-switch v-model="tceFilled" class="mt-1" dense label="Filled" />
												<v-switch v-model="tceSolo" class="mt-1" dense label="Solo" />
												<v-switch v-model="tceOutlined" class="mt-1" dense label="Outlined" />
												<v-switch v-model="tceDense" class="mt-1" dense label="Dense" />
											</div>
										</div>
									</v-list-item-action-text>
								</v-list-item-action>
							</v-list-item>
							<v-divider />

							<v-list-item>
								<v-list-item-icon>
									<v-icon v-if="compactVisualization">
										mdi-view-headline
									</v-icon>
									<v-icon v-else>
										mdi-view-sequential
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content style="align-self:start">
									<v-list-item-title>
										Visualizzazione compatta
									</v-list-item-title>
									<v-list-item-subtitle>--</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn v-if="compactVisualization" small color="error" @click="toggleCompactVisualization">
										disattiva
									</v-btn>
									<v-btn v-else small color="success" @click="toggleCompactVisualization">
										attiva
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider />


							<v-list-item>
								<v-list-item-icon>
									<v-icon v-if="cellularOpenMode">
										mdi-phone
									</v-icon>
									<v-icon v-else>
										mdi-whatsapp
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content style="align-self:start">
									<v-list-item-title>
										Modalita' apertura cellulare 
									</v-list-item-title>
									<v-list-item-subtitle>dialer (solo per mobile) o whatsapp</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn v-if="cellularOpenMode" small color="success" @click="toggleCellularOpenMode">
										whatsapp
									</v-btn>
									<v-btn v-else small color="info" @click="toggleCellularOpenMode">
										dialer
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider />

							<!-- non usato per skoda
							<v-list-item>
								<v-list-item-icon>
									<v-icon v-if="personeExitAfterSave">
										mdi-page-previous-outline
									</v-icon>
									<v-icon v-else>
										mdi-page-next-outline
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content style="align-self:start">
									<v-list-item-title>
										<v-chip x-small outlined label color="warning" class="mr-1">
											BETA
										</v-chip> Lista persone dopo salvataggio persona
									</v-list-item-title>
									<v-list-item-subtitle>Determina se dopo il salvataggio di una persona si rientri sulla lista o meno</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn v-if="personeExitAfterSave" small color="success" @click="togglePersoneExitAfterSave">
										SI
									</v-btn>
									<v-btn v-else small color="info" @click="togglePersoneExitAfterSave">
										NO
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider />
							-->
						</v-list>						
					</v-tab-item>
					<!-- tabChangePassword -->
					<v-tab-item key="tabChangePassword">
						<change-password />
					</v-tab-item>
					<!-- tabProfiloUtente -->
					<v-tab-item key="tabProfiloUtente">
						<profilo-utente></profilo-utente>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
		
		<!-- debug -->
		<template v-slot:debug>
			<v-row>
				<v-col cols="12" md="6">
					<debug-panel label="tce">
						{{ tce }}
					</debug-panel>
				</v-col>
				<v-col cols="12" md="6">
					<debug-panel label="compactVisualization">
						{{ compactVisualization }}
					</debug-panel>
				</v-col>
			</v-row>
		</template>		
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** Pagina delle impostazioni utente e del cambio pwd ****************************************
***********************************************************************************************
*/
import StandardContainer from '@/components/standard-container'
import DebugPanel from '@/components/debug-panel'
import ChangePassword from '@/components/change-password'
import ProfiloUtente from '@/components/profilo-utente'

export default {
	name: '',
	components: { StandardContainer, DebugPanel, ChangePassword, ProfiloUtente },
	data: () => {
		return {
			currentTab: 0,
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		darkTheme() { return this.$vuetify.theme.dark },
		tce() { return JSON.parse(JSON.stringify(this.$store.getters['tipologiaCampiEdit'])) },
		tceFilled: {
			get() {
				return this.tce.filled 
			},
			set(value) {
				var tce = this.tce
				tce.filled = value
				// console.warn('tceFilled.set', tce)
				this.$store.dispatch('setTipologiaCampiEdit', tce)
			}
		},
		tceOutlined: {
			get() {
				return this.tce.outlined 
			},
			set(value) {
				var tce = this.tce
				tce.outlined = value
				// console.warn('tceOutlined.set', tce)
				this.$store.dispatch('setTipologiaCampiEdit', tce)
			}
		},
		tceSolo: {
			get() {
				return this.tce.solo 
			},
			set(value) {
				var tce = this.tce
				tce.solo = value
				// console.warn('tceSolo.set', tce)
				this.$store.dispatch('setTipologiaCampiEdit', tce)
			}
		},
		tceDense: {
			get() {
				return this.tce.dense 
			},
			set(value) {
				var tce = this.tce
				tce.dense = value
				// console.warn('tceDense.set', tce)
				this.$store.dispatch('setTipologiaCampiEdit', tce)
			}
		},
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		cellularOpenMode() { return this.$store.getters['cellularOpenMode'] },
		personeExitAfterSave() { return this.$store.getters['personeExitAfterSave'] },
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		$route(to, from) {
			// imposta la tab richiesta
			if(this.$route.params.tab && this.currentTab !== parseInt(this.$route.params.tab)) this.currentTab = parseInt(this.$route.params.tab)
		},
	},
	mounted() {
		// idem come sopra perché, a volte, non va il watch sulla route e non so perché
		if(this.$route.params && this.$route.params.tab && this.currentTab !== parseInt(this.$route.params.tab)) this.currentTab = parseInt(this.$route.params.tab)
	},
	methods: {
		toggleTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark
			this.$store.dispatch('toggleTheme', this.$vuetify.theme.dark)
		},
		toggleDebugElements() {
			this.$store.dispatch('toggleDebugElements')
		},
		toggleCompactVisualization() {
			this.$store.dispatch('toggleCompactVisualization')
		},
		toggleCellularOpenMode() {
			this.$store.dispatch('toggleCellularOpenMode')
		},
		togglePersoneExitAfterSave() {
			this.$store.dispatch('togglePersoneExitAfterSave')
		},
	},
}
</script>

<style scoped lang="less">

</style>